<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-14 16:29:32
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-24 22:37:36
-->
<template>
  <div class="page-box">
    <list @load="getList">
      <div class="header flex-x center between" v-if="$route.query.status == 6">
        <div class="header__picker flex-x center" @click="showMonth = true">
          <span class="grow">{{ month }}月</span>
          <div class="header__arrow"></div>
        </div>
        <div class="btn" @click="delAll">删除</div>
      </div>

      <div
        class="order flex-x van-cell--center"
        v-for="(item, index) in list"
        :key="index"
      >
        <check-box
          class="check-box"
          v-model="item.select"
          :disabled="item.label == 0"
          v-if="$route.query.status == 6"
        />
        <div class="grow">
          <order-card
            :info="item"
            :showBtns="!!checkBtn(item, btns).length"
            :btns="checkBtn(item, btns)"
            :showSeal="!!checkIcon(item, icons)"
            :sealIcon="checkIcon(item, icons)"
            @onclickbtn="clickBtn"
            @ondetail="detail"
            :bottomText="
              $route.query.status === 3 ? '师傅预计上门时间：' + item.label : ''
            "
          />
        </div>
      </div>
    </list>

    <msg-box v-model="showNone" @onclick="$router.back()" />

    <turn-down
      v-model="showTurnDown"
      @onsubmit="submitTurnDown"
      ref="turnDown"
    ></turn-down>
  </div>
</template>

<script>
import turnDown from "../../../components/turnDown/turnDown.vue";
import { List, Toast, Checkbox, Dialog } from "vant";
import msgBox from "../../../components/msgBox/msgBox";
import orderCard from "@/components/OrderCard/OrderCard";
import { orderList, delOrders } from "../../../utils/api/workOrder";
import { order, fillTime, settlementAmount } from "../../../utils/api/service";
import { confirm } from "../../../utils/api/quotation";
import { confirmOrder as confirmAcceptance } from "../../../utils/api/acceptance";
import pageLoader from "../../../utils/load_per_page";
export default {
  components: {
    "order-card": orderCard,
    list: List,
    "msg-box": msgBox,
    "turn-down": turnDown,
    "check-box": Checkbox,
  },
  data() {
    return {
      turnDownIcon: require("../../../assets/images/user/review_turndown.png"), // 驳回图片

      listLoader: null,

      list: [],

      showNone: false, // 显示没有列表

      showTime: false, // 显示勘察时间

      showVisitTime: false, // 显示上门时间

      showTurnDown: false, // 显示驳回弹窗

      prepareInfo: {}, // 准备操作的内容

      showMonth: false, // 显示选择年月

      year: new Date().getFullYear(), // 当前选中年
      month: new Date().getMonth() + 1, // 当前选中月

      amount: 0, // 结算金额

      // 图标
      icons: [
        {
          // 待审核
          img: require("../../../assets/images/user/review_wait.png"),
          condition: { status: 0, label: 0 },
        },
        {
          // 审核未通过
          img: require("../../../assets/images/user/review_unpass.png"),
          condition: { status: 0, label: 2 },
        },
        {
          // 待派单
          img: require("../../../assets/images/user/review_dispatch.png"),
          condition: { status: 1 },
        },
        {
          // 待报价
          img: require("../../../assets/images/user/review_offer.png"),
          condition: { status: 2, label: 0 },
        },
        {
          // 待维修
          img: require("../../../assets/images/user/review_repair.png"),
          condition: { status: 3 },
        },
      ],

      // 按钮
      btns: [
        {
          text: "支付",
          method: "pay",
          type: "primary",
          condition: { status: 5 },
        },
        {
          text: "重新报修",
          method: "repairAgain",
          type: "primary",
          condition: { status: 0, label: 2 },
        },
        {
          text: "查看报价",
          method: "viewOffer",
          condition: { status: 2, label: true },
        },
        {
          text: "确认报价",
          method: "confirmOffer",
          type: "primary",
          condition: { status: 2, label: true, isAuth: 1 },
        },
        {
          text: "驳回",
          method: "turnDown",
          condition: { status: 4, label: true, isAuth: 1 },
        },
        {
          text: "立即验收",
          method: "acceptance",
          condition: { status: 4, label: true, isAuth: 1 },
        },
      ],
    };
  },

  created() {},

  methods: {
    // 获取列表
    getList() {
      if (!this.listLoader)
        this.listLoader = new pageLoader(
          orderList,
          "data.data.list.data",
          true,
          "data.data.list.last_page"
        );
      this.listLoader
        .load({
          month: `${this.year}-${this.month}`,
          status: this.$route.query.status,
        })
        .then((res) => {
          if (res && res.length === 0) this.showNone = true;
          if (res && res.length) {
            this.showNone = false;
            this.list = res;
          }
        })
        .catch((res) => {});
    },

    // 重新获取列表
    reGetList() {
      this.listLoader.reLoad();
      this.getList();
    },

    // 检查按钮并返回应该显示
    checkBtn(info, btns) {
      let result = [];

      for (let i = 0; i < btns.length; i++) {
        // 如果按钮没有条件
        if (!btns[i].condition) {
          result.push(btns[i]);
          continue;
        }

        let condition = btns[i].condition;

        if (this.checkCondition(info, condition)) result.push(btns[i]);
      }

      return result;
    },

    // 检查图标
    checkIcon(info, icons) {
      for (let i = 0; i < icons.length; i++) {
        let condition = icons[i].condition;

        if (this.checkCondition(info, condition)) {
          return icons[i].img;
        }
      }
      return "";
    },

    // 检查条件内容
    checkCondition(obj, condition) {
      let checkPassed = true; // 检查通过
      for (let j in condition) {
        let position = j.split(".");
        let value = obj;
        while (position.length > 0) {
          value = value[position[0]];
          position.splice(0, 1);
        }
        if (typeof condition[j] === "boolean") {
          if (condition[j] !== !!value) {
            checkPassed = false;
            break;
          }
        } else {
          if (condition[j] !== value) {
            checkPassed = false;
            break;
          }
        }
      }
      return checkPassed;
    },

    // 点击按钮
    clickBtn(e) {
      if (!e.detail.method) return false;
      this[e.detail.method](e.info.id, e.info);
    },

    // 支付
    pay(id, info) {
      this.$router.push({
        name: "ReviewPay",
        query: {
          id,
        },
      });
    },

    // 重新报修
    repairAgain() {
      this.$router.push({
        name: "UserRepair",
      });
    },

    // 查看报价
    viewOffer(id, info) {
      this.$router.push({
        name: "UserOffer",
        query: {
          id: info.label,
        },
      });
    },

    // 确认报价
    confirmOffer(id, info) {
      confirm({
        id: info.label,
        review: 1,
      })
        .then((res) => {
          Toast.success("确认成功");
          this.reGetList();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 驳回验收
    turnDown(id, info, btn) {
      this.prepareInfo = info;
      this.showTurnDown = true;
    },

    // 发送驳回
    submitTurnDown(e) {
      confirmAcceptance({
        id: this.prepareInfo.label,
        review: 2,
        reason: e.detail.text,
      })
        .then((res) => {
          Toast.success("驳回成功");
          this.showTurnDown = false;
          this.$refs.turnDown.clear();
          this.reGetList();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 立即验收
    acceptance(id, info) {
      confirmAcceptance({
        id: info.label,
        review: 1,
      })
        .then((res) => {
          Toast.success("验收成功");
          this.reGetList();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 详情
    detail(e) {
      this.$router.push({
        name: "ReviewMineOrderDetail",
        query: {
          id: e.info.id,
        },
      });
    },

    // 删除单个订单
    del(e) {
      Dialog.confirm({
        title: "提示",
        message: "确认删除此工单？",
      })
        .then(() => {
          delOrders({
            ids: JSON.stringify([e]),
          })
            .then(() => {
              this.reGetList();
            })
            .catch((res) => {
              Toast(res.data.msg);
            });
        })
        .catch(() => {});
    },

    // 删除选中的全部订单
    delAll() {
      let list = this.list;

      let selectList = [];

      list.forEach((item) => {
        if (item.select) selectList.push(item.id);
      });

      if (selectList.length === 0) return Toast("请先选择工单");

      Dialog.confirm({
        title: "提示",
        message: "确认删除选中的工单？",
      })
        .then(() => {
          delOrders({
            ids: JSON.stringify(selectList),
          })
            .then(() => {
              this.reGetList();
            })
            .catch((res) => {
              Toast(res.data.msg);
            });
        })
        .catch(() => {});
    },
  },
};
</script> 

<style src="@/assets/css/review/newTicket.css" scoped>
</style>

<style scoped>
.header {
  width: 100%;
  height: 98px;
  position: sticky;
  z-index: 99999;
  top: 0;
  background-color: white;
  box-sizing: border-box;
  padding: 0 33px;
}

.header__title {
  font-size: 28px;
  color: #222;
}

.header__price {
  font-size: 46px;
  color: var(--main-color);
  text-decoration: underline;
  margin-left: 18px;
}

.header__picker {
  width: 204px;
  height: 58px;
  border: 1px solid #999;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 12px 0 21px;
  font-size: 28px;
}

.header__arrow {
  width: 42px;
  height: 42px;
  background-image: url("../../../assets/images/review/form_arrow.png");
  background-size: contain;
  background-repeat: no-repeat;
}
</style>